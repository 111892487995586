<template>
  <span :id="model?.system?.id">
    <div class="banner-cta">
      <SmartLinkItemComponent :id="model?.system?.id" class="container" :component="!model?.system?.workflow">
        <div class="col-lg-12 text-left text-md-center banner-cta__container">
          <SmartLinkStandaloneElement :codename="type.elements.title.codename">
            <h2>{{ model?.elements.title.value }}</h2>
          </SmartLinkStandaloneElement>
          <SmartLinkStandaloneElement :codename="type.elements.sub_title.codename">
            <RichText :value="model?.elements.subTitle.value" />
          </SmartLinkStandaloneElement>

          <SmartLinkStandaloneElement :codename="type.elements.call_to_action.codename">
            <CallToAction v-for="cta in pageStore.getListAs<CallToActionModel>(
              model?.elements.callToAction.value
            )" :key="cta?.system?.id" :model="cta" context-class="banner-cta-btn-spacing btn btn-primary" />
          </SmartLinkStandaloneElement>
        </div>
      </SmartLinkItemComponent>
    </div>
  </span>
</template>
<script lang="ts" setup>
import type { BannerCtaModel, CallToActionModel } from '~/models'
import { contentTypes } from '~/models'
const type = contentTypes.banner_cta

const pageStore = usePageStore()
const props = defineProps<{ model: BannerCtaModel }>()
</script>
