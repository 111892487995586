<template>
  <div class="home-page">
    <div v-if="page?.elements?.hero?.value?.length > 0">
      <HomeHero
        v-for="hero in data.heroes"
        :key="hero?.system?.id"
        :model="hero"
        class="home-page__hero"
      />
    </div>

    <div v-if="page?.elements?.bannerCta?.value?.length > 0">
      <BannerCta v-for="cta in data.bannerCtas" :key="cta?.system.id" :model="cta" />
    </div>

    <div v-if="page?.elements?.peopleSearch?.value?.length > 0">
      <div v-for="ps in data.peopleSearchers" :key="ps.system.id">
        <LazyPeopleSearchBlock :model="ps" />
      </div>
    </div>

    <div v-if="page?.elements?.featuredContent?.value?.length > 0" class="home-page__featured">
      <LazyFeaturedContent
        v-for="item in data.featuredContent"
        :key="item?.system.id"
        :model="item"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import type {
  BannerCtaModel,
  CallToActionModel,
  FeaturedContentBlockModel,
  HomeHeroModel,
  PeopleSearchBlockModel,
  WebSpotlightRootModel
} from '~/models'

const pageStore = usePageStore()
const page = pageStore.page as WebSpotlightRootModel

const data = {
  heroes: pageStore.getListAs<HomeHeroModel>(page.elements.hero.value),
  bannerCtas: pageStore.getListAs<BannerCtaModel>(page.elements.bannerCta.value),
  peopleSearchers: pageStore.getListAs<PeopleSearchBlockModel>(page.elements.peopleSearch.value),
  featuredContent: pageStore.getListAs<FeaturedContentBlockModel>(
    page.elements.featuredContent.value
  ),
  ctas: pageStore.getListAs<CallToActionModel>(page.elements.bannerCta.value)
}
</script>
