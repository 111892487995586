<template>
  <SeoMetatags />
  <SmartLinkItem :item-id="pageStore.page?.system.id">
    <HomePage />
  </SmartLinkItem>
  <ScriptTags />
</template>
<script lang="ts" setup>
import { useCssVar } from '@vueuse/core'
import { useCDNHeaders } from '#nuxt-multi-cache/composables'

const config = useRuntimeConfig()
if (config.enableCdn) {
  useCDNHeaders((helper) => {
    helper
      .public()
      .setNumeric('maxAge', Number(config.cacheMaxAge))
      .setNumeric('staleIfError', 43200)
  })
}

const pageStore = usePageStore()

const route = useRoute()
const idParam = route.query.itemid
if (idParam) {
  const url = await pageStore.getUrlByItemId(idParam as string)
  if (url) {
    navigateTo(url)
  }
}

const { data, status, error } = await useAsyncData(`page-home`, async () => {
  await pageStore.fetchHome()
})

function setThemeColorVariables() {
  const hexColor = useCssVar(`--theme-gold`).value
  const themeColor = new ThemeColor(hexColor)
  const style = document.documentElement.style
  style.setProperty('--theme-color', themeColor.hex)
  style.setProperty('--theme-color-r', themeColor.r.toString())
  style.setProperty('--theme-color-g', themeColor.g.toString())
  style.setProperty('--theme-color-b', themeColor.b.toString())
}

onMounted(() => setThemeColorVariables())
</script>
